.column-container {
    width: 100%;
    display: flex;
    user-select: none;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
}

.retro__header {
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.retro__phase-title {
	display: flex;
	min-width: 150px;
	margin-left: 15px;
	align-items: center;
}


.retro__phase-title h2 {
	margin: 0;
	color: var(--titleColor);
}

.retro__phase-info {
	width: 20px;
	height: 20px;
	margin-top: 7px;
	margin-left: 10px;
}

.retro__phase-info svg {
	fill: var(--titleColor);
}

.retro__timer {
	top: 5px;
	right: 20px;
	min-width: 60px;
	position: relative;
}

.finished-section {
	min-width: 250px;
	padding-right: 25px;
}

.finished-section button {
	margin-left: 10px;
}