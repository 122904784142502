/* Navigation Styles */
/* Header */
.logo {
    opacity: 1;
    display: flex;
    color: #fff;
    text-decoration: none;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.logo img {
    width: 130px;
}

/* Mobile menu */
#menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 1em;
    color: var(--titleColor);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    padding: 0 0.4em;
}
#menu-icon:hover {
    opacity: 0.85;
    text-decoration: none;
    border: none;
}

.landing-navbar {
    background-color: transparent;
    float: none;
    max-height: 0;
    overflow: hidden;
    -moz-transition: max-height 0.4s;
    -o-transition: max-height 0.4s;
    -webkit-transition: max-height 0.4s;
    transition: max-height 0.4s;
}
.landing-navbar .menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: var(--background);
}
.landing-navbar .menu li {
    display: flex;
    font-weight: bold;
    list-style-type: none;
    justify-content: center;
}
.landing-navbar .menu li a {
    display: block;
    text-decoration: none;
    color: var(--titleColor);
    padding: 0 0.8em;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 10px;
    border-bottom: 1px solid transparent;
}
.landing-navbar .menu li > a:hover {
    background-color: var(--lightBlue);
}
.landing-navbar .menu li > a.active {
    background-color: var(--lightBlue);
}

.expand {
    max-height: 20em;
    /* FIX: Adding this is messing up to opening animation */
    overflow: visible;
}

.landing__login,
.landing__signup {
    padding: 20px;
}

@media only screen and (min-width: 868px) {
    .wrap {
        padding-left: 1em;
        padding-right: 1em;
    }

    .logo {
        margin-left: 0;
    }

    .logo, .landing-navbar {
        display: inline-block;
        overflow: visible;
    }

    .header .logo {
        position: relative;
        top: 10px;
        left: 10px;
    }

	/* Mobile menu icon */
    #menu-icon {
        display: none;
    }

    .landing-navbar {
        float: right;
        max-height: none;
    }
    .landing-navbar .menu {
        background-color: transparent;
    }
    .landing-navbar .menu li {
        list-style-type: none;
        display: inline-block;
        text-align: center;
    }
    .landing-navbar .menu li a {
        display: block;
        border-bottom: 0;
    }
    .landing-navbar .menu li a.active {
        box-shadow: 0 -3px 0 var(--darkBlue) inset;
    }
}
