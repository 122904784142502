.discuss__container {
	width: 100%;
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	justify-content: center;
}

.card-group__staging-area {
	width: 320px;
	position: relative;
	overflow: hidden;
}

.card-group__rotation-area {
	display: flex;
	transition: all 1s;
	transform: translateX(var(--translate));
}

.card-group__conatiner {
	margin: 10px;
	height: inherit;
	min-width: 300px;
}

.card-group__title {
	height: 24px;
	display: flex;
	font-size: 24px;
	margin-bottom: 10px;
	justify-content: center;
}

.action-item__sidebar {
	top: 75px;
	display: flex;
	position: relative;
	align-self: flex-start;
}

.addActionItem {
	width: 40px;
	height: 40px;
	display: flex;
	position: relative;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
}

.addActionItem svg {
	font-size: 20px;
} 

.card-group__conatiner.endOfRetro img{
	width: 100%;
}