.order-confirmed__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.order-confirmed__box {
    display: flex;
    min-width: 450px;
    align-items: center;
	border-radius: 10px;
	margin-bottom: 50px;
    flex-direction: column;
    justify-content: center;
	background-color: white;
	box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.order-decription {
    width: 80%;
    margin: 25px 0;
}

.order-decription__price {
    width: 80%;
    font-size: 80px;
    text-align: center;
    color: var(--lightBlue);
}

.order-decription__row {
    width: 100%;
    display: flex;
    padding: 5px 0;
    justify-content: space-between;
}

.order-actions__row {
    display: flex;
}

.mg-lt__20 {
    margin-left: 20px;
}

.order-description__row--value {
    font-weight: bold;
    color: var(--titleColor);
}

.color-green {
    color: var(--success);
}

.title-color {
    color: var(--titleColor);
}

.mg-50 {
    margin: 50px;
}