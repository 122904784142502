.email__tag--wrapper {
    margin-bottom: 15px;
}

.tag-item {
    height: 30px;
    font-size: 14px;
    border-radius: 30px;
    align-items: center;
    display: inline-flex;
    padding: 0 4px 0 1rem;
    margin: 0 0.3rem 0.3rem 0;
    background-color: #d4d5d6;
}

.tag-item > button {
    padding: 0;
    width: 22px;
    height: 22px;
    border: none;
    font: inherit;
    display: flex;
    line-height: 1;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.email__info svg {
    margin-right: 5px;
}

.email__info {
    color: var(--darkGray);
    font-size: 12px;
}