.settings-sidebar {
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.75s;
    width: clamp(150px, 30%, 250px);
    background-color: var(--background);
    box-shadow: -1px 0 5px 3px #efefef;
}

.settings-sidebar h2 {
    color: var(--titleColor);
}

.settings-sidebar .settings-sidebar__content {
    width: 100%;
    padding: 10px 0;
}

.settings-sidebar ul {
    padding: 0;
}

.settings-sidebar li {
    width: 100%;
    display: flex;
    padding: 5px 20px;
    align-items: center;
    list-style-type: none;
}

.settings-sidebar li:hover,
.settings-sidebar li.active {
    cursor: pointer;
    color: var(--darkBlue);
    background-color: #fff;
    box-shadow: inset 4px 0 0 var(--darkBlue);
}

.settings-sidebar li svg {
    margin-right: 10px;
}

.settings__content--container {
    width: auto;
    display: flex;
    min-width: 250px;
    overflow-x: auto;
    align-items: center;
    justify-content: center;
    margin-left: clamp(150px, 30%, 250px);
}

.retro-button.upload__button {
    margin-bottom: 5px;
}

.settings__form {
    width: 70%;
    min-width: 250px;
    max-width: 750px;
}
 
.settings__content {
    display: flex;
    width: 50%;
}

.settings__row {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.stretch-setting__row {
    min-width: 300px;
}

.settings__row--description {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    align-items: flex-start;
}

.settings__row--value {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.settings__row--value img {
    width: clamp(75px, 20%, 100px);
    margin-right: 10px;
}

.display-image__text {
    margin-bottom: 5px;
}

.profile__submit {
    display: flex;
    align-items: center;
    justify-content: center;
}