.dashboard-container {
	width: 70%;
	margin: 0 auto;
	position: relative;
	padding-bottom: 50px;
}

.dashboard-title {
	display: flex;
	font-size: 24px;
	margin-top: 20px;
	font-weight: 700;
	min-width: 250px;
	position: relative;
	flex-direction: row;
	align-items: center;
	color: var(--titleColor);
}

.dashboard-title svg {
	position: relative;
	font-size: var(--svg-size);
}

.dashboard-title__create:hover {
	cursor: pointer;
	color: var(--darkBlue);
}

.dashboard-title__create {
	margin-left: 5px;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	border-radius: var(--svg-size);
}

.dashboard-title__see-all {
	right: 0;
	bottom: 4px;
	color: black;
	font-size: 16px;
	position: absolute;
	text-decoration: none;
}