.user-cards__owner {
	display: flex;
	padding-bottom: 10px;
	align-items: center;
	flex-flow: row nowrap;
	justify-content: center;
}

.user-cards__owner-image {
	width: 25%;
	height: 35px;
	display: flex;
	justify-content: center;
}

.user-cards__owner-name {
	font-size: 20px;
}

.user-cards__container {
	padding: 10px;
}

.user-cards__row {
	display: flex;
	padding: 10px 0;
	position: relative;
	align-items: center;
	justify-content: space-between;
}

.user-cards__row:before {
	top: 0;
	content : "";
	left    : 20%;
	height  : 1px;
	width   : 60%;
	position: absolute;
	border-top: 1px solid var(--gray);
}

.user-cards__reporter-image {
	width: 40px;
	height: 30px;
	display: flex;
	justify-content: center;
}

.user-cards__content {
	max-width: 85%;
    overflow-wrap: break-word;
}

.user-cards__up-vote {
	display: flex;
	align-items: center;
	justify-self: center;
}

.user-cards__up-vote button {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
}

.user-cards__up-vote button svg{
	font-size: 24px;
}

.user-cards__icons {
	display: flex;
}

.up-vote__button {
	margin-right: 10px;
	border: 1px solid var(--darkBlue);
}

.up-vote__button.active {
	color: white;
	background-color: var(--darkBlue);
}