.input2.textarea-theme.actionItemInput {
    width: 90%;
    height: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
}

.actionItems__dropdown {
    margin-top: 10px;
}

.scrollable-y {
    height: 500px;
    overflow-y: scroll;
}

.actionItems__dropdown select{
    margin: 0;
    height: 30px;
}

.actionItems__icons {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
}

.addActionItems {
    width: 40px;
    height: 40px;
    margin: 10px;
    position: relative;
    border-radius: 40px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border: 1px solid var(--darkGray);
}

.addActionItems.retro-end {
    height: 34px;
}

.addActionItems.submit-action-item:hover {
    border: 1px solid var(--successState);
    background-color: var(--successState);
}

.addActionItems.clear-action-item:hover {
    border: 1px solid var(--lightError);
    background-color: var(--lightError);
}

.addActionItems svg {
    font-size: 20px;
}