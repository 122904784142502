.retro-controller__container {
	left: 50%;
	width: 60%;
	z-index: 2;
	bottom: 10px;
	height: 60px;
	margin: auto;
	position: fixed;
	min-width: 350px;
	max-width: 450px;
	border-radius: 30px;
	background-color: white;
	transform: translate(-50%, 0);
	box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.retro-controller {
	position: absolute;
}

.retro-controller__button {
	width: 60px;
	height: 60px;
	border: none;
	border-radius: 30px;
	background-color: var(--darkBlue);
}

.retro-controller__button:hover {
	background-color: var(--lightBlue);
	cursor: pointer;
}

.retro-controller__button--alert {
	background-color: var(--error);
}

.retro-controller__button.retro-controller__button--alert:hover {
	background-color: var(--lightError);
}

.retro-controller__button:disabled {
	background-color: var(--disabled);
}

.retro-controller__button:disabled:hover {
	cursor: not-allowed;
}

.retro-controller__button svg {
	margin: auto;
	display: block;
	color: white;
	font-size: 28px;
}

.retro-controller__button:focus {
	outline: none
}

.retro-controller__button--prev {
	left: 0;
}

.retro-controller__button--next {
	right: 0;
}

.retro-controls__container {
	left: 50%;
	height: 60px;
	position: absolute;
	transform: translate(-50%, 0);
}

.retro-controls {
	position: relative;
	display: inline-block;
}

.retro-controls button {
	width: 60px;
	height: 60px;
	font-size: 35px;
	border-radius: 10px;
}

.retro-controls button svg {
	height: 45px;
	display: inline-block;
	vertical-align: middle;
}

.vertical-divider {
	border-right: var(--gray) solid 1px;
}