.base-timer {
    /* Match the label size, if changed */
    width: 60px;
}
  
.base-timer__circle {
    fill: none;
    stroke: none;
}

.red {
    stroke: var(--error);
}

.orange {
    stroke:  var(--warning);
}

.green {
    stroke: var(--success);
}

.base-timer__path-remaining {
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
}

.base-timer__svg {
    transform: scaleX(-1);
}

.base-timer__path-elapsed {
    stroke-width: 10px;
    stroke: grey;
}

.base-timer__label {
    top: 0;
    font-size: 16px;
    position: absolute;

    /* Size should match the parent container */
    width: 60px;
    height: 60px;

    /* Create a flexible box that centers content vertically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;

}