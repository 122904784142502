.end-retro__container {
    width: 75%;
    margin: auto;
    display: flex;
    margin-top: 100px;
    flex-direction: column;
}

.end-retro__title {
    font-weight: 700;
    font-size: 1.5em;
    color: var(--titleColor);
}

.board-data__container {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
}

.board-info {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.board-info h1 {
    color: var(--titleColor);
}

.board-info img {
    width: 40px;
}

.action-items__container {
    padding: 10px;
    margin-top: 20px;
    min-width: 450px;
	border-radius: 10px;
	margin-bottom: 50px;
	background-color: white;
}

.action-items__container.end-of-retro {
    margin: 0;
}

.action-items__input-container {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.action-items__input-section {
    width: 60%;
    padding: 5px;
    display: flex;
    align-items: center;
}
.action-items__dropdown-section {
    width: 30%;
    padding: 5px;
    display: flex;
    align-items: center;
}
.action-items__icon-section {
    width: 10%;
    padding: 5px;
    display: flex;
    min-width: 90px;
    align-items: center;
}

.no-margin {
    margin: 0;
}

.invert {
    transform: scale(-1, 1);
}

.mg-tp__20{
    margin-top: 20px;
}

.action-items__end-retro-title--container {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.end-retro__action-items--dropdown {
    min-width: 150px;
    margin-right: 20px;
}