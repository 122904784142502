/* Base styles for the element that has a tooltip */
.tooltip {
	position: relative;
	cursor: pointer;
}

/* Base styles for the entire tooltip */
.tooltip:before,
.tooltip:after {
	position: absolute;
	visibility: hidden;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	-webkit-transition: 
	opacity 0.2s ease-in-out,
	visibility 0.2s ease-in-out,
	-webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	-moz-transition:    
	opacity 0.2s ease-in-out,
	visibility 0.2s ease-in-out,
	-moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	transition:         
	opacity 0.2s ease-in-out,
	visibility 0.2s ease-in-out,
	transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform:    translate3d(0, 0, 0);
	transform:         translate3d(0, 0, 0);
	pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
	opacity: 1;
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before {
	z-index: 1;
	content: "";
	background: transparent;
	border: 6px solid transparent;
}

/* Base styles for the tooltip's content area */
.tooltip:after {
	z-index: 1;
	color: #fff;
	font-size: 14px;
	line-height: 1.2;
	max-width: 200px;
	padding: 6px 10px;
	border-radius: 2px;
	white-space: nowrap;
	font-weight: normal;
	background: rgba(0,0,0,0.75);
	content: attr(data-tooltip-content);
}

/* Directions */
/* Top (default) */
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
	bottom: 100%;
	left: 50%;
}

.tooltip:before,
.tooltip-top:before {
	margin-left: -6px;
	margin-bottom: -12px;
	border-top-color: #000;
	border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
.tooltip:after,
.tooltip-top:after {
	margin-left: -65%;
}

.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
	-webkit-transform: translateY(-12px);
	-moz-transform:    translateY(-12px);
	transform:         translateY(-12px); 
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
	right: 100%;
	bottom: 50%;
	left: auto;
}

.tooltip-left:before {
	margin-top: 22px;
	margin-bottom: -6px;
	margin-right: -11px;
	border-top-color: transparent;
	border-left-color: #000;
	border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
	-webkit-transform: translateX(-12px);
	-moz-transform:    translateX(-12px);
	transform:         translateX(-12px); 
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
	top: 100%;
	bottom: auto;
	left: 50%;
}

.tooltip-bottom:before {
	margin-top: -12px;
	margin-bottom: 0;
	border-top-color: transparent;
	border-bottom-color: #000;
	border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
	-webkit-transform: translateY(12px);
	-moz-transform:    translateY(12px);
	transform:         translateY(12px); 
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
	bottom: 50%;
	left: 100%;
}

.tooltip-right:before {
	margin-top: 8px;
	margin-bottom: 0;
	margin-left: -12px;
	border-top-color: transparent;
	border-right-color: #000;
	border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
	-webkit-transform: translateX(12px);
	-moz-transform:    translateX(12px);
	transform:         translateX(12px); 
}

/* Info Tooltip Right */
.info-tooltip-right:before,
.info-tooltip-right:after {
	bottom: -250%;
	left: 130%;
	min-width: 200px;
	white-space: normal;
}

.info-tooltip-right:before {
	margin-bottom: 55px;
	margin-left: -225px;
	border-top-color: transparent;
	border-right-color: #000;
	border-right-color: hsla(0, 0%, 20%, 0.9);
}

.info-tooltip-right:hover:before,
.info-tooltip-right:hover:after,
.info-tooltip-right:focus:before,
.info-tooltip-right:focus:after {
	-webkit-transform: translateX(12px);
	-moz-transform:    translateX(12px);
	transform:         translateX(12px); 
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
	top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
	margin-left: 0;
	margin-bottom: -16px;
}