.vote-container {
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: flex-end;
}

.show-votes {
	display: flex;
	margin-right: 10px;
}

.add-vote button {
	display: flex;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
}

.vote-icons {
	border: none;
	padding: 1px;
	display: flex;
	border-radius: 10px;
	background-color: transparent;
}

.vote-icons svg {
	color: var(--voteColor);
}