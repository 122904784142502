.board__container {
	display: flex;
	min-height: 400px;
}

.board__container.active {
	cursor: grabbing;
	cursor: -webkit-grabbing;
}

.board__content {
	display: flex;
	width: 100%;
	flex-direction: column;
}

textarea {
	resize: vertical;
}

.board__404--conatiner,
.unauthorized__container,
.invalid-order__container,
.free-trial__container--expired {
	width: 100vw;
	height: 90vh;
	display: flex;
	overflow-y: scroll;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.request-access {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.board__404--staging,
.unauthorized__staging {
	width: clamp(350px, 50%, 750px);
}

.board__404--staging svg {
	font-size: 120px;
}

.unauthorized__staging img {
	width: 100%;
}