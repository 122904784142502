@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--translate: 0%;
	--gray: #a8a8a8;
	--star: #FFD700;
	--blue : #5697ff;
	--svg-size: 1.3rem;
	--error: #e55c5c;
	--success: #5ce5a1;
	--neutral: #5ca1e5;
	--warning: #e5a15c;
	--puzzles: #a15ce5;
	--disabled: #d3d3d3;
	--darkBlue: #3d87ff;
	--darkGray: #505050;
	--percentComplete: 0%;
	--lightGray: #d3d3d3;
	--hoverGray: #f0f0f0;
	--voteColor: #3d67ff;
	--lightBlue: #70a7ff;
	--lightError: #ed8f8f;
	--background: #f4f8fc;
	--titleColor: #292833;
	--successState: #26c484;
	--bannerAlert: #936316;
	--bannerDanger: #f54180;
	--backgroundHoverGray: #f6f6f6;
	--passwordStrengthColor: #FF4B47;
	--bannerAlertBackground: #FDEDD3;
	--bannerDangerBackground: #fdd0df;
	--subscriptionStatusLabel: #26c484;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	background-color: var(--background);
	font-family: "SF UI Text",
		"Helvetica Neue",
		"Segoe UI",
		Oxygen,
		Ubuntu,
		Cantarell,
		"Open Sans",
		sans-serif;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input {
	background-color: var(--bg-default-100);
}

.mt-0 {
	margin-top: 0;
}

.loader-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background);
}

.loader-container img {
	height: 100px;
	width: 100px;
}

.spinner {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	border-radius: 50%;
	position: absolute;
	background-color: var(--darkBlue);
	animation: sk-bounce 2.0s infinite ease-in-out;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
	animation-delay: -1.0s;
	-webkit-animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
	0%, 100% { -webkit-transform: scale(0.0) }
	50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
	0%, 100% { 
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	} 50% { 
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
} 

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.column__header.column__theme--error,
.template__column.column__theme--error,
.card-type__status.column__theme--error {
	background-color: var(--error);
}

.column__header.column__theme--success,
.template__column.column__theme--success, 
.card-type__status.column__theme--success {
	background-color: var(--success);
}

.column__header.column__theme--neutral,
.template__column.column__theme--neutral,
.card-type__status.column__theme--neutral {
	background-color: var(--neutral);
}

.column__header.column__theme--warning,
.template__column.column__theme--warning,
.card-type__status.column__theme--warning {
	background-color: var(--warning);
}

.column__header.column__theme--puzzles,
.template__column.column__theme--puzzles,
.card-type__status.column__theme--puzzles {
	background-color: var(--puzzles);
}

[data-hide="true"] {
	display: none !important;
}

[data-visually-hidden="true"] {
	opacity: 0;
}

[data-visually-hidden="false"] {
	opacity: 1;
}

[data-fade-in="true"] {
	animation: fadein 0.7s;
	-moz-animation: fadein 0.7s; /* Firefox */
	-webkit-animation: fadein 0.7s; /* Safari and Chrome */
	-o-animation: fadein 0.7s; /* Opera */
}
@keyframes fadein {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
@-moz-keyframes fadein { /* Firefox */
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
@-o-keyframes fadein { /* Opera */
	from {
		opacity:0;
	}
	to {
		opacity: 1;
	}
}

.input-row {
	width: 100%;
	display: block;
	position: relative;
}

.input-row label {
	font-size: 18px;
	position: absolute;
	top: 10px;
}

.input-theme {
	outline: none;
	border: none;
}

.textarea-theme {
	outline: none;
	border: none;
}

.textarea-theme:focus, .input-theme:focus {
	border-color: transparent !important;
}

.input-theme:focus::-webkit-input-placeholder { color:transparent; }
.input-theme:focus:-moz-placeholder { color:transparent; }
.input-theme:focus::-moz-placeholder { color:transparent; }
.input-theme:focus:-ms-input-placeholder { color:transparent; }

.textarea-theme:focus::-webkit-input-placeholder { color:transparent; }
.textarea-theme:focus:-moz-placeholder { color:transparent; }
.textarea-theme:focus::-moz-placeholder { color:transparent; }
.textarea-theme:focus:-ms-input-placeholder { color:transparent; }

.input-theme::-webkit-input-placeholder { color: #999999; }
.input-theme:-moz-placeholder { color: #999999; }
.input-theme::-moz-placeholder { color: #999999; }
.input-theme:-ms-input-placeholder { color: #999999; }

.textarea-theme::-webkit-input-placeholder { color: #999999; }
.textarea-theme:-moz-placeholder { color: #999999; }
.textarea-theme::-moz-placeholder { color: #999999; }
.textarea-theme:-ms-input-placeholder { color: #999999; }

/*------------------------------------------------------------------
[ INPUT2 ]*/

.wrap-input2 {
	width: 100%;
	position: relative;
	margin-bottom: 24px;
	border-bottom: 2px solid #cdcdcd;
}

.input2 {
	width: 100%;
	display: block;

	font-size: 15px;
	line-height: 1.2;
	color: #555555;
}

.focus-input2 {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	pointer-events: none;
}

.focus-input2::before {
	left: 0;
	width: 0;
	height: 2px;
	content: "";
	bottom: -2px;
	display: block;
	position: absolute;

	transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-webkit-transition: all 0.4s;

	background: var(--darkBlue);
	background: linear-gradient(45deg, var(--darkBlue), var(--lightBlue));
	background: -o-linear-gradient(45deg, var(--darkBlue), var(--lightBlue));
	background: -moz-linear-gradient(45deg, var(--darkBlue), var(--lightBlue));
	background: -webkit-linear-gradient(45deg, var(--darkBlue), var(--lightBlue));
}

.focus-input2::after {
	content: attr(data-placeholder);
	display: block;
	width: 100%;
	opacity: 0.7;
	position: absolute;
	top: 0px;
	left: 0;

	font-size: 18px;
	color: var(--darkBlue);
	line-height: 1.2;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

/*---------------------------------------------*/
input.input2 {
	height: 45px;
}

input.input2 ~ .focus-input2::after {
	top: 16px;
}

input.col-input ~ .focus-input2::after {
	left: 10px;
	color: #000;
}

.input2.col-input:disabled {
	cursor: not-allowed;
}

textarea.input2 {
	min-height: 115px;
	padding-top: 13px;
	padding-bottom: 13px;
}

textarea.input2 ~ .focus-input2::after {
	top: 16px;
	left: 0;
}

.input2:focus ~ .focus-input2::after {
	top: -13px;
	opacity: 1;
}

.comment-input:focus ~ .focus-input2::after {
	top: -24px;
}

.input2:focus ~ .focus-input2::before {
	width: 100%;
}

.has-val.input2 ~ .focus-input2::after {
	top: -13px;
}

.col-has-val.input2 ~ .focus-input2::after {
	top: -24px;
}

.has-val.input2 ~ .focus-input2::before {
	width: 100%;
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
	position: relative;
}

.alert-validate::before {
	top: 50%;
	max-width: 70%;
	position: absolute;
	border-radius: 2px;
	padding: 4px 25px 4px 10px;
	background-color: white;
	border: 1px solid #c80000;
	content: attr(data-validate);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0px;
	pointer-events: none;

	color: #c80000;
	font-size: 13px;
	line-height: 1.4;
	text-align: left;

	visibility: hidden;
	opacity: 0;

	-webkit-transition: opacity 0.4s;
	-o-transition: opacity 0.4s;
	-moz-transition: opacity 0.4s;
	transition: opacity 0.4s;
}

.alert-validate::after {
	content: "\0021";
	display: block;
	position: absolute;
	color: #c80000;
	font-size: 16px;
	font-weight: bolder;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 8px;
}

.alert-validate:hover:before {
	visibility: visible;
	opacity: 1;
}

@media (max-width: 992px) {
	.alert-validate::before {
		visibility: visible;
		opacity: 1;
	}
}

footer {
	width: 100%;
	height: 300px;
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
	background-color: white;
}

.logo__section {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.social__icons {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
}

footer .links__section ul li {
	list-style-type: none;
	font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

footer .links__section ul li a{
	font-weight: bold;
	text-decoration: none;
	color: var(--titleColor);
	font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
}

footer .links__section ul li a:hover {
	color: var(--darkBlue);
}
