.retro-text-button {
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    color: var(--darkBlue);
    text-decoration: underline;
}

.retro-text-button:hover {
    cursor: pointer;
    color: var(--titleColor);
}

.retro-text-button:focus {
    border: none;
    box-shadow: none;
    color: var(--titleColor);
}