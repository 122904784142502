.members__container {
    display: flex;
    margin-top: 25px;
}

.member__wrapper {
    margin: 0 10px;
    display: flex;
    position: relative;
}

.member__admin-icon:focus,
.member__delete-icon:focus,
.member__wrapper:hover > .member__action--icons button {
    opacity: 1;
}

.member__action--icons {
    right: -10px;
    height: 100%;
    position: absolute;
}

.member__action--icons button {
    opacity: 0;
    width: 24px;
    height: 24px;
    border: none;
    display: flex;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
}

.member__admin-icon {
    margin-top: 10px;
}

.member__wrapper img {
    width: 64px;
    height: 64px;
}

.member__wrapper svg {
    font-size: 24px;
    position: absolute;
}

.member__wrapper .admin-badge {
    top: 0;
    left: -5px;
    color: var(--darkBlue);
}