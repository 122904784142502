.merged-cards__section {
	margin-bottom: 20px;
}

.merged-cards__container {
	min-height: 100px;
	position: relative;
	margin-bottom: 10px;
}

.merged-cards__group-title input:focus {
	outline: none;
}

.merged-cards__expand-button {
	border: none;
	display: inline-block;
	border-radius: 5px;
	background-color: transparent;
}

.merged-cards__group-title {
	display: inline-block;
}

.merged-cards__group-title input {
	margin-left: 10px;
	border: none;
}