.cards {
	width : 100%;
	display : flex;
    flex-wrap : wrap;
	flex-direction : column;
    padding: 0 15px 15px 15px;
}

.card {
    width: 100%;
    height: auto;
	margin: 10px 0;
    max-width: 100%;
    position: relative;
	border-radius: 5px;
    white-space: initial;
    word-wrap: break-word;
	padding: 40px 10px 25px 10px;
	background: rgb(255, 255, 255);
    transition: all 100ms cubic-bezier(0, 0, 0.2, 1) 0s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cards > .dragging {
    opacity: 0.3;
    border: 10px dotted var(--darkBlue);
}

.cards > .invalid-dragging {
    opacity: 0.3;
    cursor: not-allowed;
    border: 10px dotted var(--gray);
}

.overflow__hidden {
    overflow: hidden;
}

.card.card__no-icons,
.card.card__appreaciation {
    padding: 25px 10px 30px 10px;
}

.blur {
    filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    -moz-filter: blur(5px);
    -webkit-filter: blur(5px);
    pointer-events: none;
}

.card-type {
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
	border-radius: 4px 0px 0px;
}

.card-type__status {
	top: -16px;
    width: 32px;
    left: -16px;
	height: 32px;
    position: absolute;
    border-radius: 100px;
}

.card-icon__edit,
.card-icon__delete {
    top: 5px;
    opacity: 0;
    width: 32px;
    height: 32px;
    display: flex;
    position: absolute;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
}

.card-icon__edit {
    right: 40px;
}

.card-icon__delete {
    right: 10px;
}

.card-icon__edit:focus,
.card-icon__delete:focus,
.card:hover .card-icon__edit,
.card:hover .card-icon__delete {
    opacity: 1;
}

.card-icons {
    top: 5px;
    right: 5px;
    width: 64px;
    height: 32px;
    position: absolute;
}

.merged-cards {
    left: 50%;
    position: absolute;
    transform: translate(-50%);
}

.card__author-section {
    display: flex;
    bottom: 5px;
    right: 5px;
    position: absolute;
    justify-content: flex-end;
}

.card__author-section img {
    height: 20px;
    margin-top: 10px;
}

.postion-realtive {
    position: relative;
}

.postion-absolute {
    position: absolute;
}
