.subscription-info__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--titleColor);
    width: clamp(300px, 40%, 600px);
}

.subscription-info__container h2 {
    margin-bottom: 0;
}

.icon__container svg{
    margin-top: 20px;
    font-size: 50px;
    color: var(--darkGray);
}

.plan__multiple-teams {
    display: flex;
}

.team-cost__container {
    padding: 5px;
    display: flex;
    margin: 5px 10px;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    border: 2px solid transparent;
}

.team-cost__container p {
    margin: 5px 0;
}

.team-cost__container.active {
    border: 2px solid var(--darkBlue);
}

.team-cost__container .team-cost {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: var(--darkBlue);
}

.team-cost__count {
    margin: 5px 0;
    padding: 2px 10px;
    font-weight: bold;
    border-radius: 10px;
    background-color: var(--lightGray);
}

.plan__term,
.plan__team-size {
    margin: 0;
}

.plan__cost {
    font-size: 40px;
    display: flex;
    color: var(--darkBlue);
}

.plan__cost .previous-price {
    font-size: 20px;
    padding: 0 5px 5px 0;
    align-self: flex-end;
    color: var(--error);
    text-decoration: line-through;
}

.plan__features--container {
    width: 60%;
    display: flex;
    min-width: 300px;
    margin: 20px 0;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    color: var(--titleColor);
    background-color: white;
    box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #fff;
}

.feature__divider {
    width: 60%;
    color: var(--gray);
}

.plan__features--container ul {
    padding: 14px;
    list-style-type: none;
}

.plan__features--container ul li {
    display: flex;
    font-weight: bold;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
}

.plan__features--container ul svg {
    font-size: 20px;
    margin-right: 20px;
    color: var(--success);
}

.plan__features--container ul .star {
    color :var(--star);
}