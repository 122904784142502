.retro-button {
    border-radius: 5px;
    background-color: transparent;
    border: var(--darkBlue) solid 1px;
}

.retro-button__small {
    padding: 5px 10px;
}

.retro-button__large {
    width: 20vw;
    padding: 5px;
    min-width: 100px;
    max-width: 150px;
}

.retro-button__xl {
    padding: 5px;
    max-width: 200px;
}

.retro-button__xs {
    padding: 5px;
    font-size: 10px;
}

.retro-button--danger {
    border: red solid 1px;
    color: red;
}

.retro-button:hover {
    color: white;
    cursor: pointer;
    border: 1px solid black;
    background-color: var(--darkBlue);
}

.retro-button.finished {
    color: white;
    font-weight: bold;
    border-color: var(--successState);
    background-color: var(--successState);
}

.retro-button.finished:hover {
    opacity: 0.7;
    border-color: var(--lightError);
    background-color: var(--lightError);
}

.retro-button:disabled:hover {
    color: black;
    cursor: not-allowed;
    border: 1px solid var(--gray);
    background-color: var(--gray);
}

.retro-button .user-check {
    width: 16px;
    margin-left: 5px;
}

.inverted-theme {
    color: white;
    font-weight: bold;
    background-color: var(--darkBlue);
}