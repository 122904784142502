.range-input {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
.range-input input {
    -webkit-appearance: none;
    width: 200px;
    height: 2px;
    background: var(--darkBlue);
    border: none;
    outline: none;
}
.range-input input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #eee;
    border: 2px solid var(--darkBlue);
    border-radius: 50%;
}
.range-input input::-webkit-slider-thumb:hover {
    background: var(--darkBlue);
}
.range-input .value {
    color: var(--darkBlue);
    text-align: center;
    font-weight: 600;
    line-height: 40px;
    height: 40px;
    overflow: hidden;
    margin-left: 10px;
}
.range-input .value div {
    transition: all 300ms ease-in-out;
}