.popOver__container {
	top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: transparent;
}

.popOver {
	z-index: 4;
	width: auto;
	display: flex;
	position: absolute;
	border-radius: 10px;
	flex-direction: row;
	background-color: white;
	transform: translate(-60px, 0);
	box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.25);
}

.popOver::after {
	width: 0;
	height: 0;
	left: 82px;
	content: '';
	bottom: -8px;
	display: block;
	position: absolute;
	border-top: 8px solid white;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	filter: drop-shadow(0 0 30px #333);
}

.popOver__top {
	top: -55px;
}

.popOver__bottom {
	left: -20px;
	width: 250px;
	flex-direction: column;
}

.popOver__notifications {
	top: 60px;
	left: -300%;
	width: auto;
	margin: 5px 2px;
	padding: 0 10px;
}

.popOver__notifications ul {
	margin: 0;
	padding: 0;
}

.popOver__bottom::after,
.popOver__notifications::after{
	width: 0;
	height: 0;
	left: 70%;
	top: -8px;
	content: '';
	display: block;
	border-top: none;
	position: absolute;
	border-bottom: 8px solid white;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	filter: drop-shadow(0 0 30px #333);
}

.popOver__row {
	width: 100%;
	height: 50px;
	display: flex;
	margin: 5px 0;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

.send-invite {
	width: 30px;
	height: 30px;
	display: flex;
	font-size: 16px;
	margin-left: 10px;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	border: solid 1px #000;
}

.send-invite:hover {
	color: white;
	cursor: pointer;
	background-color: var(--darkBlue);
}

.invite-input__wrapper {
	display: flex;
	flex-direction: column;
}

.input__info--wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.invite-input__info {
	font-weight: bold;
	visibility: hidden;
}

.show-invite__info {
	visibility: visible;
	color: var(--success);
}

.input__info--wrapper .show-invite__error {
	visibility: visible;
	color: var(--error);
}

@keyframes blinker {  
	50% { opacity: 0; }
}

.time-seperator {
	font-weight: bolder;
	padding-left: 5px;
	animation: blinker 2s linear infinite;
}

.seperator {
	font-weight: bold;
	text-align: center;
	color: var(--titleColor);
}

.controls-wrapper {
	position: relative;
}

.timer-input-wrapper {
	display: flex;
	padding: 10px 15px;
}

.timer-input-wrapper input {
	width: 40px;
}

.popOver .controls-wrapper button {
	height: 45px;
	width: 45px;
}

.minutes-wrapper,
.seconds-wrapper {
	overflow: hidden;
}

.minutes-wrapper input,
.seconds-wrapper input {
	border: none;
	font-size: 16px;
	padding-left: 5px;
}

.minutes-wrapper input:focus,
.seconds-wrapper input:focus {
	outline: none;
}

.member-choice__row {
	padding: 5px 15px;
}

.member-choice__row p {
	margin: 5px 0;
	font-size: 14px;
}

.member-choice__row label {
	font-weight: bold;
	margin-left: 5px;
	color: var(--titleColor);
}

.mt-10 {
	margin-top: 10px;
}