
.login__container {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--titleColor);
}

.form-container {
	width: 30%;
	min-width: 400px;
	display: flex;
	padding: 75px;
	border-radius: 30px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: white;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.form-container.signup-spacing {
	padding: 30px 75px;
}

.login-link {
	font-size: 14px;
	text-decoration: none;
}

.goto-login {
	font-size: 14px;
}

.login__greeting {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.login__greeting a, .login__text {
	display: flex;
	align-items: center;
	justify-content: center;
}

.login__greeting img {
	width: 50%;
	max-width: 200px;
	min-width: 150px;
}

.login__form, .signup__form {
	display: flex;
	gap: 24px;
	margin-top: 40px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.login__form a,
.signup__form a {
	color: var(--titleColor);
}

.login__form a:hover,
.signup__form a:hover {
	cursor: pointer;
	color: var(--darkBlue);
}

.signup {
	font-size: 16px;
	text-align: center;
	color: var(--titleColor);
}

.signup__image {
	margin-top: 20px;
}

.signup__name {
	width: 100%;
	display: flex;
	gap: 8px;
	flex-direction: row;
}

.login__form,
.signup__form {
	width: 100%;
	min-width: 200px;
	max-width: 400px;
}

.login-button {
	width: 100%;
	margin: 25px;
	padding: 10px;
	font-size: 16px;
	font-weight: bold;
	color: var(--blue);
	border-radius: 20px;
	background-color: var(--blue);
	border: 1px solid var(--blue);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.login-button:hover {
	cursor: pointer;
	color: var(--blue);
	background-color: white;
}

.input-icon {
	right: 10px;
	bottom: 10px;
	font-size: 18px;
	position: absolute;
}

.input-icon:hover {
	cursor: pointer;
	color: var(--darkBlue);
}

.form-error {
	width: 100%;
	gap: 10px;
	display: flex;
	font-weight: 500;
	font-size: 0.80rem;
	align-items: center;
	border-radius: 10px;
	padding: 14px 20px;
	color:  var(--bannerDanger);
	background-color: var(--bannerDangerBackground);
}

.password-rules {
	font-size: 14px;
	color: var(--error);
}

.password-rules p {
	margin: 0;
}

.condition-wrapper {
	overflow: hidden;
	position: relative;
}

.condition-passed {
	position: absolute;
}

.forgot-password {
	padding: 0;
	font-size: 12px;
	margin-top: 10px;
	color: var(--titleColor);
}

.forgot-password:focus,
.back-to-login:focus {
	border: none;
	box-shadow: none;
	color: var(--darkBlue);
}

.forgot-password:hover,
.back-to-login:hover {
	cursor: pointer;
	color: var(--darkBlue);
}

.back-to-login {
	padding: 0;
	font-size: 14px;
	color: var(--titleColor);
}

.mg-25 {
	margin: 25px;
}