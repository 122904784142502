.subscriptions__container {
    height: 100px;
    width: 100px;
    background-color: black;
}

.card__wrapper {
    width: 100%;
    display: flex;
    padding: 20px 0;
    min-width: 330px;
    max-width: 600px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 50px;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.subscription-details__row {
    width: 80%;
    display: flex;
    margin: 5px 0;
    align-items: center;
    justify-content: space-between;
}

.subscription-details__row--value .credit-card__icon {
    top: 0;
    width: 30px;
    position: relative;
}

.subscription-details__row--value {
    display: flex;
    font-weight: bold;
    justify-content: center;
}

.plan__label {
    display: flex;
    padding: 0 5px;
    font-size: 10px;
    border-radius: 5px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--subscriptionStatusLabel);
}

.plan__label.save-up {
    padding: 2px 10px;
    font-weight: bold;
}

.plan__container {
    display: flex;
    margin: 0 10px;
    align-items: center;
    flex-flow: column wrap;
}

.billing-interval {
    position: relative;
    right: 0;
}

.settings-padding {
    padding: 50px 0;
}

.mt-20 {
    margin-top: 20px;
}