.payment__container {
    display: flex;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background-color: white;
    box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #fff;
}

.checkout__container .flex {
    width: clamp(300px, 30%, 500px);
}

.credit-card__container h2 {
    color: var(--titleColor);
}

.credit-card__form {
    width: 90%;
}

.credit-card__row {
    display: flex;
}

.number__section {
    width: 80%;
    margin-right: 10px;
}

.cvv__section {
    width: 20%;
}

.expiry__section {
    display: flex;
    width: 50%;
}

.zipcode__section {
    display: flex;
    width: 50%;
    margin-left: 10px;
}

.expiry__section select {
    margin: 0 3px;
}

.credit-card__icon {
    right: 0;
    top: 20px;
    width: 35px;
    position: absolute;
}

.submit__payment img {
    width: 20px;
}

.stripe__container {
    display: flex;
    justify-content: flex-end;
}

.stripe__container img {
    width: 120px;
    margin: 10px 0;
}