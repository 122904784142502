.modal {
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	overflow: auto;
	position: fixed;
	padding-top: 80px;
	background-color: rgba(0,0,0,0.75);
}

.modal__content {
	width: 90%;
	margin: auto; 
	height : auto;
	min-width: 410px;
	position: relative;
	border-radius: 20px;
	padding-bottom: 50px;
	background-color: white;
}

.modal__content__maxWidth {
	width: 70%;
	max-width: 640px;
	padding-bottom: 100px;
}

.modal__alert {
	top: 50%;
	margin: auto; 
	height : auto;
	position: relative;
	border-radius: 20px;
	padding-bottom: 25px;
	background-color: white;
	transform: translateY(-100%);
	width: clamp(360px, 50%, 450px);
}

.modal__alert--actions {
	display: flex;
	justify-content: space-evenly;
}

.close button {
	width: 35px;
	height: 35px;
	margin-right: 30px;
}

.modal__header--controls:hover, 
.modal__header--controls:focus {
	color: #000;
	cursor: pointer;
	text-decoration: none;
}

.modal__header--controls {
	display: flex;
	font-size: 30px;
	color: #aaaaaa;
	font-weight: bold;
	border-radius: 35px;
	align-content: center;
	justify-content: center;
}

.modal__header {
	display: flex;
	padding: 30px 0;
	justify-content: space-between;
}

.modal__back {
	width: 10%;
	min-width: 50px;
	max-width: 100px;
	margin-left: 30px;
}

.width0 {
	width: 0;
}

.modal_title {
	margin: 0;
	font-weight: 700;
	font-size: 1.5rem;
	color: var(--titleColor);
}

.modal__submit {
	bottom: 0;
	width: 100%;
	height: 15%;
	display: block;
	min-height: 60px;
	max-height: 100px;
	position: absolute;
}

.modal__submit button {
	border: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	color: #fff;
	font-size: 25px;
	background: var(--darkBlue);
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.modal__submit button:focus {
	outline: none;
}

.modal__submit button:hover {
	cursor: pointer;
	background: var(--blue);
}

.modal__form {
	display: flex;
	margin: 25px 100px;
	align-items: center;
	flex-direction: column;
}

.modal__form .error__icon {
	font-size: 60px;
}

.modal__form--alert {
	margin: 0 30px 10px 30px;
}

.modal__form--alert.bottom-margin {
	margin: 0 30px 30px 30px;
}

.modal__form--checkbox {
	width: 100%;
	display: flex;
	margin-top: 20px;
	align-items: center;
	justify-content: center;
}

.assigned-team {
	width: 100%;
	text-align: left;
	font-weight: bold;
	margin-bottom: 20px;
}

.margin-b-20 {
	margin-bottom: 20px;
}

.dropdown-container {
	display: flex;
	margin-bottom: 20px;
	justify-content: center;
}

.modal__price--container {
	display: flex;
	align-items: center;
}

.modal__alert--savings {
	font-size: 12px;
	padding: 3px 5px;
	margin-right: 10px;
	border-radius: 20px;
	background-color: var(--success);
}

.modal__alert--price {
	font-size: 32px;
	color: var(--darkBlue);
}

.modal__alert--addition-wanring {
	display: flex;
	font-size: 12px;
	margin-top: 15px;
	align-items: center;
	color: var(--darkGray);
}

.modal__alert--addition-wanring svg {
	width: 30px;
	font-size: 24px;
	color: var(--gray);
}

#alert-modal__success img {
    width: 100%;
    height: 20px;
}

.feedback__icons {
	width: 50%;
	display: flex;
	margin-bottom: 20px;
	justify-content: space-evenly;
}

.feedback__icons button {
	width: 50px;
	height: 50px;
	display: flex;
	border-radius: 50px;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
}

.feedback__icons svg {
	font-size: 30px;
}

.feedback__icons button:hover {
	cursor: pointer;
}

.feedback__icons button svg.active {
	fill: var(--darkBlue);
}

.modal__alert.top-60 {
	top: 60%;
}