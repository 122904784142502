.template {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-evenly;
}

.template-column__container {
	margin-bottom: 15px;
}

.template-column__container .template__column {
	display: flex;
	width: 200px;
	height: 75px;
	flex-direction: column;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	justify-content: space-between;
}

.template-column__text {
	margin-top: 10px; 
	margin-left: 20px;
}

.template-column__text .template-column__title {
	font-weight: 700;
	font-size: 1.3rem;
	color: var(--titleColor);
}

.template-column__text .template-column__description {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--titleColor);
}

.fading-column-effect {
	height: 30px;
	background-color: var(--titleColor);
	mask-image: linear-gradient(to bottom, black 10%, transparent 100%);
	-webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 100%);
}