.switch-button {
    width: 150px;
    color: #000;
    overflow: hidden;
    text-align: center;
    position: relative;
    font-size: 14px;
	border-radius: 30px;
    padding-right: 70px;
    background: white;
	display: inline-block;
}
.space-out {
    letter-spacing: 1px;
}
.switch-button:before {
	top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 80px;
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    content: attr(data-option);
    justify-content: center;
}
.switch-button-checkbox {
	top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
}
.switch-button-checkbox:checked + .switch-button-label:before {
    transform: translateX(70px);
    transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
    position: relative;
    padding: 5px 0;
    display: block;
    user-select: none;
    pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
    content: "";
    background: var(--darkBlue);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
    position: relative;
}