.cancel__container {
    height: 1230px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__wrapper.side__padding {
    padding: 0 50px;
}

.row {
    width: 100%;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row.space-evenly {
    justify-content: space-evenly;
}

.discount__banner {
    font-size: 28px;
    margin-right: 5px;
    color: var(--darkBlue);
}

.error {
    font-weight: bold;
    color: var(--error);
}

.discount__container{
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
}

.cancel__reverse {
    padding: 3px 10px;
}

.font-14 {
    font-size: 24px;
}