.actionItem__card {
    width: 100%;
    height: auto;
	margin: 10px 0;
    position: relative;
	border-radius: 5px;
	padding: 25px 10px;
    align-items: center;
    display: inline-flex;
    white-space: initial;
    word-wrap: break-word;
	background: rgb(255, 255, 255);
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0s;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.actionItem__card img {
    height: 35px;
    margin-right: 15px;
}

.actionItem__content {
    width: 75%;
}

.actionItem__icons {
    right: 0;
    opacity: 0;
    display: flex;
    position: absolute;
    margin-right: 10px;
    flex-direction: column;
}

.actionItem__card:hover > .actionItem__icons {
    opacity: 1;
}

.actionItem__icons button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 35px;
    justify-content: center;
}