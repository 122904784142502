.checkout__container {
    width: 100%;
    display: flex;
    padding: 25px 0;
    align-items: center;
    justify-content: center;
}

.page-title {
    text-align: center;
}

.page-title h1 {
    color: var(--titleColor);
}