.display-vote__container {
	height: 15px;
	display: flex;
	justify-content: center;
}

.display-vote {
	width: 15px;
	height: inherit;
	margin-right: 2px;
	border-radius: 50%;
	background-color: var(--titleColor);
}