@import './dashboard.css';

.board__description h5 {
	margin:0px;
	font-size:1.4em;
	font-weight:700;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.board__description p {
	font-size:12px;
}

.center {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.board__card {
	width: 14em;
	height: 18em;
	margin: 30px;
	overflow: hidden;
	position: relative;
	flex-direction: row;
	border-radius: 16px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: inline-flex;
	-ms-flex-direction: row;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
	-webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.board__card a {
	width: 100%;
	display: inline-flex;
}

.board__card a:focus{
	border: black solid 5px;
}

/* Top Half of card, image. */

.board__image {
	top: 0px;
	height: 6em;
	width: 14em;
	padding: 1em 2em;
	position: Absolute;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-image: url('https://raw.githubusercontent.com/arvindrk/my-retro/master/cardBackground.webp');
}

/* Bottom Card Section */

.board__description {
	bottom: 0em;
	width: 14em;
	height: 12em;
	position: absolute;
	text-align: center;
	padding:  0.5em 1em;
	text-overflow: ellipsis;
	background-color:  #FAFAFC;
	transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Bottom Icons */

.board__icons--container {
	bottom: 5px;
	width: 100%;
	display: flex;
	position: absolute;
	justify-content: space-evenly;
}

.board__icons {
	margin: 5px;
	width: 2.5em;
	height: 2.5em;
	display: flex;
	border-radius: 2.5em;
	-webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.board__icons--delete {
	margin: 5px;
	width: 2em;
	height: 2em;
}

.board__icons svg {
	margin: auto;
	font-size: 16px;
}

.board__icons img {
	width: 100%;
}

/* board Cards Hover States */

.board__card:hover .board__description {
	height: 0em;
	padding: 0px 1em;
}
.board__card:hover .board__image {
	height: 18em;
}

.board__card:hover .board__icons {
	background-color: white;
}

.board__card:hover .board__icons:hover {
	color: var(--blue);
	cursor: pointer;
}

.board__image--title {
	top: 30%;
	opacity: 0;
	color: white;
	font-size: 1.2em;
	text-align: center;
	position: Relative;
	transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}

.board__card:hover .board__image--title {
	opacity: 1;
}

.action-progress {
	width: 100%;
	height: .25rem;
	margin: 1.5rem 0;
	border-radius: .5rem;
	background-color: #e1e8ef;
}

.action-progress.action-progress--hover {
	margin: 3rem 0;
}

.action-progress--fill {
	height: .25rem;
	margin: 1.5rem 0;
	border-radius: .5rem;
	background-color: var(--darkBlue);
}

.board__empty--wrapper {
	display: flex;
	justify-content: center;
}

.board__empty--wrapper img {
	min-width: 200px;
	max-width: 500px;

}

.first-board__notification {
	font-size: 12px;
	color: var(--gray);
}