.teams-container {
	width: 70%;
	margin: 0 auto;
	position: relative;
}

.teams-container.authorization__error {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.authorization__error {
	height: 100vh;
}

.teams-container .error__icon {
	font-size: 60px;
	color: var(--error);
}