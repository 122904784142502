.notifications {
    position: relative;
}

.notifications__from {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notifications__from img {
    width: 35px;
}

.notifications__message {
    width: 200px;
    display: flex;
    font-size: 14px;
    align-items: center;
    white-space: normal;
    justify-content: center;
}


.notifications__actions {
    display: flex;
    justify-content: flex-end;
}

.notifications__actions button {
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 35px;
    align-items: center;
    justify-content: center;
}

.notifications__actions button:disabled {
    cursor: not-allowed;
}

.notifications__actions button svg {
    font-size: 25px;
}

.empty-notifications__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.empty-notifications__wrapper img {
    margin: 15px;
}

.empty-notifications {
    width: 250px;
}

.empty-notification__text {
    margin-bottom: 20px;
}