.column {
	width: 20%;
    display: flex;
	max-width: 300px;
    min-width: 250px;
    margin: 10px 5px;
    min-height: 250px;
    transition: all 1s;
	border-radius: 15px;
    flex-direction: column;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    @media screen and (max-width: 500px) {
      min-height: 200px;
      min-width: 200px;
    }
}

.column__header {
    width: 100%;
    height: 75px;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: var(--lightBlue);
}

.column__header h3 {
    margin: 0;
    padding: 10px 10px;
}

.column-card__input {
    padding-left: 5px;
    padding-right: 30px;
}