.progress-bar_wrap {
    width: 65%;
    height: 5px;
    background: #F6F6FA;
    display: inline-flex;
    vertical-align: middle;
    overflow: hidden;
    border-radius: 5px;
}

.settings-input__wrapper .progress-bar_item {
    display: inline-flex;
    height: 100%;
    width: 33.33%;
    visibility: hidden;
    transition: background-color .2s, visisility .1s;
}

.settings-input__wrapper .active {
    visibility: visible;
    background-color: var(--passwordStrengthColor);
}

.progress-bar_text {
    display: inline-block;
    font-size: 14px;
    color: #aaa;
    margin-left: 5px;
    transition: .2s;
}
