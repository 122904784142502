.side-drawer__container {
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
}

.side-drawer {
    top: 0;
    right: 0;
    z-index: 4;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    background-color: white;
    transition: transform 0.75s;
    transform: translateX(150%);
    width: clamp(200px, 30%, 400px);
    box-shadow: -1px 0 5px 3px #efefef;
}

.size-drawer__close {
    left: 0;
    top: 25px;
    padding: 3px;
    display: flex;
    position: absolute;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: white;
    transform: translateX(-150%);
}

.size-drawer__close svg {
    font-size: 24px;
}

.side-drawer h2 {
    color: var(--titleColor);
}

.side-drawer.active{
    transform: translateX(0);
}

.side-drawer .side-drawer__content {
    width: 100%;
    padding: 10px;
}

.side-drawer__divider {
    width: 90%;
}

.settings-input__wrapper.max-votes__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.max-votes__wrapper.settings-input__wrapper label {
    width: auto;
    font-size: 16px;
    text-transform: capitalize;
}

.max-votes__wrapper .board-settings__max-votes {
    width: 20%;
}