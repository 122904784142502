.navbar {
	z-index: 1;
	width: 100%;
	height: 60px;
	display: flex;
	position: relative;
	white-space: nowrap;
	justify-content: space-between;
}

.navbar__logo-section--settings {
	display: flex;
	align-items: center;
	justify-content: center;
	width: clamp(150px, 30%, 250px);
}

.navbar__logo-section img {
	height: 45px;
	width: auto;
}

.navbar__logo-section a {
	margin: 10px;
    display: inline-flex;
    text-decoration: none;
    transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navbar__profile-section {
	display: flex;
	align-items: center;
}

.profile-button, .login-button {
	margin: 10px;
	padding: 10px 20px;
}

.anonymous-user__wrapper {
	display: flex;
}

.anonymous-user__wrapper a {
	margin: 0 10px;
}