.action-item__row {
    width: 100%;
    padding: 5px 10px;
}

.action-item__row .switch-button {
    float: right;
}

.action-item__info {
    display: inline-flex;
    align-items: center;
    width: 75%;
}

.action-item__row img {
    height: 40px;
    margin-right: 20px;
}

.action-item__info-display {
    display: flex;
    flex-direction: column;
}

.action-item__belongs-to {
    font-size: 12px;
    color: var(--darkGray);
}