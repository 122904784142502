.retro-link {
    color: black;
    border-radius: 5px;
    text-decoration: underline;
    background-color: transparent;
}

.retro-link:hover {
    color: var(--darkBlue);
}

.retro-link__button {
    font-size: 14px;
    padding: 5px 30px;
    text-decoration: none;
    border: var(--darkBlue) solid 1px;
}

.retro-link__button:hover {
    color: white;
    cursor: pointer;
    border: 1px solid black;
    background-color: var(--darkBlue);
}

.upgrade__nav {
    margin: 0 10px;
}

.freeTrial__text {
    padding: 8px 12px;
    display: flex;
    gap: 10px;
    font-weight: 500;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    color: var(--bannerAlert);
    background-color: var(--bannerAlertBackground);
}