.row.flex-start {
    align-items: flex-start;
}

#landing__content h1,
#landing__content .section__content .section__title {
    font-size: calc(12px + 20 * ((100vw - 300px) / 1300));
    font-weight: bold;
    padding: 20px 0;
}

#landing__content h2,
#landing__content h3 {
    font-size: calc(10px + 12 * ((100vw - 300px) / 1300));
    font-weight: bold;
    padding: 12px 0;
}

#landing__content p {
    font-size: calc(10px + 4 * ((100vw - 300px) / 1300));
}

#home {
    color: white;
    text-align: center;
    background-color: var(--darkBlue);
}

#home .section__content {
    padding-top: 5vh;
}

#contact .feature__image img {
    height: 150px;
    width: 150px;
    border: 8px solid white;
}

.section__content {
    display: flex;
    padding-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.section__content .row {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.section__content .row .free-trial__container {
    order: 3;
}

.section__content .row .premium__container {
    order: 2;
}

.section__content .row .team__container {
    order: 1;
}

#home img {
    width: 70%;
    max-width: 1500px;
}

.landing__section {
    width: 100%;
}

.feature__row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.feature__description,
.step__description {
    order: 2;
    display: flex;
    padding: 0 50px;
    max-width: 500px;
    text-align: justify;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.step__description h3, .feature__description h3 {
    margin: 0;
}

.feature__image {
    order: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.step__image {
    order: 1;
    display: flex;
    padding: 0 50px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.feature__image .designed-by {
    font-weight: bold;
    font-style: italic;
    font-size: 2px;
}

.step__image img {
    width: 100%;
}

.feature__image img {
    width: 100%;
    max-width: 180px;
}

.feature__image.rounded img{
    border-radius: 50%;
}

.social__icons svg {
    margin: 5px 10px;
}

#contact {
    margin-bottom: 200px;
}

.terms__container {
    width: 100%;
    display: flex;
    margin: auto;
    max-width: 1500px;
    flex-direction: column;
    justify-content: center;
}

.feature__description a {
    text-decoration: none;
    color: var(--titleColor);
}

@media only screen and (max-width: 768px) {
    .feature__row.connect img{
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .feature__row {
        padding: 0 10vw;
        flex-direction: row;
    }
    .feature__row.connect img{
        width: 50%;
        max-width: 500px;
    }
    .step__description {
        max-width: 500px;
    }
    .feature__row .step__image {
        padding: 0;
        max-width: 1000px;
    }
    .feature__row.odd-row .feature__description,
    .feature__row.odd-row .step__description {
        order: 1;
    }
    .feature__row.odd-row .feature__image,
    .feature__row.odd-row .step__image {
        order: 2;
    }
    .odd-row .feature__image,
    .odd-row .step__image {
        justify-content: flex-start;
    }
    .feature__image,
    .step__image {
        justify-content: flex-end;
    }
    .feature__image img {
        max-width: 300px;
    }
    .section__content .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    #landing__content p{
        font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
    .section__content .row .free-trial__container {
        order: 1;
    }
    .section__content .row .premium__container {
        order: 2;
    }
    .section__content .row .team__container {
        order: 3;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .feature__row.connect img{
        width: 50%;
        max-width: 200px;
    }
}