@keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--value); }
}

div[role="progressbar"] {
    animation: growProgressBar 3s 1 forwards;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    display: grid;
    place-items: center;
    background: 
    radial-gradient(closest-side, #fff0 80%, transparent 0 99.9%, white 0),
    conic-gradient(#fff calc(var(--value) * 1%), #0000 0);
}

.member-activity__container {
    z-index: -1;
    display: flex;
    min-width: 65px;
    padding-top: 20px;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: white;
    min-height: calc(100vh - 70px);
    transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.member-activity__container .member-image__wrapper {
    display: flex;
    margin: 5px 0;
}

.member-image__wrapper.voting-status {
    position: relative;
}

.member-activity__container .member-image__wrapper img {
    width: 50px;
}

.offline__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
}

.finished__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    color: var(--darkBlue);
}

.typing__overlay {
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
}

.user-typing,
.user-typing:before,
.user-typing:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.user-typing {
    z-index: 1;
    font-size: 4px;
    margin: 10px auto;
    position: relative;
    text-indent: -9999em;
    color: var(--titleColor);
    animation-delay: -0.16s;
    transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
}
.user-typing:before,
.user-typing:after {
  content: '';
  position: absolute;
  top: 0;
}
.user-typing:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.user-typing:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
