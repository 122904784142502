.retro-nav {
	width: 100%;
	height: 70px;
	display: flex;
	position: relative;
	align-content: center;
	background-color: white;
	justify-content: space-between;
	border-bottom: 1px solid #f7f7f7;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.retro-nav::after{
	bottom: 0;
	content: '';
	height: 3px;
	position: absolute;
	transition: all 0.8s;
	width: var(--percentComplete);
	background-color: var(--darkBlue);
}

.retro-navbar__logo-section {
	height: inherit;
	display: inline-flex;
}

.retro-navbar__logo-section img {
	margin: 10px;
	height: 45px;
	width: auto;
}

.retro-navbar__board-title {
	margin-top: 15px;
	font-weight: 700;
	font-size: 1.5rem;
	min-width: 175px;
	color: var(--titleColor);
}

.retro-navbar__members-section {
	display: flex;
	height: inherit;
	align-items: center;
	flex-wrap: row nowrap;
	justify-content: space-evenly;
}

.retro-navbar__profile-section {
	position: relative;
}