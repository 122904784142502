.statusBar__container {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusBar {
    width: 100px;
    height: 5px;
    border-radius: 10px;
}