.action-item-box {
	min-width: 450px;
	border-radius: 10px;
	margin-bottom: 50px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.action-item-box.spacing {
	margin-top: 25px;
	padding: 20px 0;
}

.action-tabs {
	list-style-type: none;
	padding-inline-end: 40px;
	border-bottom: 1px solid #e1e8ef;
}

.action-tabs li {
	display: inline-block;
	padding: 20px;
}

.action-tabs li:hover{
	cursor: pointer;
	color: var(--blue);
}

.action-tabs .active{
	color: #0049be;
	border-bottom: 5px solid var(--darkBlue);
}

.action-items__empty {
	display: flex;
	padding-bottom: 40px;
	justify-content: center;
}

.action-items__empty img {
	min-width: 250px;
	max-width: 300px;
}