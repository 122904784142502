.team__card {
	margin: 10px;
	overflow: hidden;
	position: relative;
	padding: 10px 20px;
	flex-direction: row;
	border-radius: 16px;
	display: inline-flex;
	text-decoration: none;
	color: var(--titleColor	);
	border: #f0f5ff solid 1px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.team__title {
	display: flex;
	align-items: center;
	justify-content: center;
}

.team__title img {
	width: 24px;
	margin-right: 10px;
}

.team__card:hover {
	cursor: pointer;
	transform: scale(1.05);
	border: var(--lightBlue) solid 1px;
}

.user-teams__empty {
	display: flex;
	justify-content: center;
}

.user-teams__empty img {
	min-width: 100px;
	max-width: 130px;
}