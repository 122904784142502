.settings-input__wrapper {
    display: block;
    width: 100%;
    margin: 10px auto;
    text-align: left;
}
  
label.settings__label {
    width: 100%;
    display: block;
    font-size: 12px;
    position: relative;
    text-transform: uppercase;
    color: var(--titleColor);
}

.settings-input__wrapper input {
    width: 100%;
    height: 35px;
    margin: 10px 0;
    transition: .2s;
    padding: 0 15px;
    border-radius: 5px;
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid var(--lightBlue);
}

.input-1.inputError {
    border: 2px solid var(--error);
}

.settings-input__wrapper input:focus {
    outline: none;
    -moz-outline: none;
    -webkit-outline: none;
}