.my-profile__container {
	position: relative;
}

.hide-dropdown {
	z-index: 1;
	width: 100%;
	height: 70px;
	display: flex;
	position: absolute;
	background-color: var(--background);
}

.my-profile {
	z-index: 2;
	margin: 10px;
	display: flex;
	font-size: 16px;
	cursor: pointer;
	min-width: 120px;
	padding: 10px 20px;
	position: relative;
	background: white;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--blue);
}

.my-profile__border {
	border: black solid 1px;
}

.my-profile img {
	height: 24px;
	padding-left: 10px;
}

.dropdown {
	left: 0;
	top: 48px;
	padding: 0;
	width: 120px;
	z-index: -1;
	margin: 10px;
	list-style: none;
	position: absolute;
	background-color: white;
	-webkit-transform: translate(0, -150%);
	-moz-transform: translate(0, -150%);
	-ms-transform: translate(0, -150%);
	transform: translate(0, -150%);
	-webkit-transition: all 0.5s 0.1s;
	-moz-transition: all 0.5s 0.1s;
	-ms-transition: all 0.5s 0.1s;
	transition: all 0.5s 0.1s;
}

.dropdown__border {
	border: black solid 1px;
}

.my-profile__container #settings__anchor {
	padding: 0;
	color: black;
	text-decoration: none;
}

.dropdown li {
	width: 100%;
	padding: 10px 0;
	font-weight: 500;
	text-align: center;
}

.landing-navbar .my-profile__container {
	padding: 5px 0;
}

.landing-navbar .menu .dropdown li {
	padding: 0;
}

.dropdown li:hover  {
	cursor: pointer;
	background-color: var(--hoverGray);
}
  
.my-profile:hover ~ .dropdown,
.my-profile:focus-within ~ .dropdown,
.dropdown:focus-within,
.dropdown:hover {
	z-index: 1;
	transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-webkit-transform: translate(0, 0);
}

.my-profile:hover,
.my-profile:focus,
.my-profile:focus-within {
	overflow: visible;
}