.invalid-order__container svg {
    height: 10%;
    width: 100%;
}

.invalid-order__container .status-code {
    font-size: 100px;
}

.error__description {
    margin-bottom: 20px;
}