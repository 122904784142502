.appreciations__container {
    min-width: 450px;
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.appreciations__row {
    width: 100%;
    padding: 5px 10px;
    position: relative;
}

.appreciations__row .switch-button {
    float: right;
}

.appreciations__info {
    display: inline-flex;
    align-items: center;
}

.appreciations__row img {
    height: 40px;
}

.appreciations__row .board-image {
    margin-right: 20px;
}

.appreciations__info-display {
    display: flex;
    flex-direction: column;
}

.appreciations__belongs-to {
    font-size: 12px;
    color: var(--darkGray);
}

.up-vote__container {
    position: absolute;
    right: 50px;
}

.up-vote__container svg {
    font-size: 24px;
}