.pricingContainer {
    display: flex;
}

.free-trial__reminder {
    font-weight: bold;
}

.free-trial__reminder--expired {
    font-size: 24px;
    color: var(--error);
}

.free-trial__days {
    font-size: 60px;
    font-weight: bold;
    color: var(--error);
}

.free-trial__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
}

.premium__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
}

.team__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
}

.pricing-modal__min-width {
	min-width: 1050px;
}